import { render, staticRenderFns } from "./SupportButton.vue?vue&type=template&id=cb065c1c&scoped=true&"
import script from "./SupportButton.vue?vue&type=script&lang=js&"
export * from "./SupportButton.vue?vue&type=script&lang=js&"
import style0 from "./SupportButton.vue?vue&type=style&index=0&id=cb065c1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb065c1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBadge,VIcon,VSheet,VSlideXReverseTransition})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
